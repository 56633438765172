import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import * as forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import * as Constants from './constants'
import * as Suspense from './suspense'
import { CustomFieldError } from './custom-field-error'
import { FieldLabel } from './field-label'
import { Input } from './input'
import { getFieldNormalizer } from './get-field-normalizer'
import { getFieldParser } from './get-field-parser'
import { useDebouncedState } from './use-debounce-state'
import { usePrev } from './use-prev'

const FieldResponse = loadable(() => import('./field-response'))

export function InputField(props) {
  const form = forms.useFormContext()
  const field = forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })
  const translate = Herz.I18n.useTranslate(
    () => [field.label, field.placeholder],
    [field.label, field.placeholder]
  )

  const prevValue = usePrev(field.value)
  const [showError, setShowError, setShowErrorImmediate] = useDebouncedState(
    1000,
    false
  )
  const visited = ![
    forms.FieldStatus.PRISTINE,
    forms.FieldStatus.ABSENT,
  ].includes(field.status)

  function handleChange(e) {
    if (props.customErrorKey) {
      props.onClearCustomErrorKey()
    }
    field.onChange(e)
  }

  React.useEffect(() => {
    if (field.errors?.length === 0) {
      setShowErrorImmediate(false)
    } else if (!field.value && visited) {
      setShowErrorImmediate(true)
    } else {
      if (prevValue !== field.value) {
        setShowErrorImmediate(false)
      }
      setShowError(Boolean(field.value))
    }
  }, [field.value, field.errors, visited, prevValue])

  return (
    <common.Flex flexDirection="column">
      {translate(field.label) && (
        <FieldLabel disabled={props.disabled} htmlFor={field.name}>
          {translate(field.label)}
        </FieldLabel>
      )}
      <Input
        visited={visited}
        invalid={showError || Boolean(props.customErrorKey)}
        valid={field.errors?.length === 0 && !props.customErrorKey}
        autoComplete={props.autoComplete}
        autoCorrect={props.autoCorrect}
        autoFocus={props.autoFocus}
        currency={props.currency}
        disabled={props.disabled}
        inputMode={props.inputMode}
        maxLength={props.maxLength}
        minLength={props.minLength}
        name={field.name}
        id={`${form.name}-${field.name}`}
        prependIcon={props.prependIcon}
        placeholder={translate(field.placeholder) ?? ''}
        suppressVisualFeedback={props.suppressVisualFeedback}
        type={props.type}
        value={field.value}
        onChange={handleChange}
        onBlur={field.onBlur}
      />
      <Suspense.Boundary fallback={null}>
        {props.suppressVisualFeedback ? null : props.customErrorKey ? (
          <CustomFieldError translationKey={props.customErrorKey} />
        ) : (
          <FieldResponse scope={props.scope} />
        )}
      </Suspense.Boundary>
    </common.Flex>
  )
}

InputField.defaultProps = {
  disabled: false,
  type: 'text',
  normalize: value => value || undefined,
}

InputField.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  currency: PropTypes.oneOf(Constants.CurrencyCodes),
  customErrorKey: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputMode: PropTypes.string,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  normalize: PropTypes.func,
  parse: PropTypes.func,
  prependIcon: PropTypes.element,
  scope: PropTypes.string,
  suppressVisualFeedback: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password']),
  onClearCustomErrorKey: PropTypes.func,
}
